import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const OPG_TWITTER_USERNAME = "@one_page_guide"

function Seo({ description, lang, meta, title, thumbnail_name }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
          }
        }
      }
    `
  )
  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description

  let metaArray = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `author`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:site`,
      content: OPG_TWITTER_USERNAME,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  if (thumbnail_name) {
    const thumbnailPath = `${site.siteMetadata.siteUrl}/thumbnails/${thumbnail_name}`
    metaArray = metaArray.concat([
      {
        property: `og:image`,
        content: thumbnailPath,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: thumbnailPath,
      },
    ])
  } else {
    metaArray = metaArray.concat([
      {
        name: `twitter:card`,
        content: `summary`,
      },
    ])
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={metaTitle}
      title={metaTitle}
      titleTemplate={`%s | ${metaTitle}`}
      meta={metaArray.concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
